/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
*/

/*
39.108.118.171:61883
test
dp@test
*/

import Paho from 'paho-mqtt'
import '@/main.js'
import store from './store'

var clientid = GetID()
var client;

// connect the client
var options = {
  // userName: 'admin',
  // password: 'WLDLB@dp2gxy',
  userName: 'test',
  password: 'dp@test',
  keepAliveInterval : 1,
  onSuccess: onConnect,
  onFailure: function (e) {
    console.log('onFailure', e)
    client.connect(options)
  }
}

MQTTInit()

function MQTTInit(){
  // client = new Paho.Client('49.232.223.115', Number('61623'), clientid);
  //  client = new Paho.Client('39.108.118.171', Number('61623'), clientid);
  client = new Paho.Client('162.14.122.208', Number('61623'), clientid);
  client.onConnectionLost = onConnectionLost
  client.onMessageArrived = onMessageArrived
  client.connect(options)
  console.log('Hello MQTT', clientid)
}

// called when the client connects
function onConnect () {
  // Once a connection has been made, make a subscription and send a message.
  client.subscribe('CXG2/#')
  var message = new Paho.Message('//this is a Test Command, send by self')
  message.destinationName = 'CXG2'
  client.send(message)
  console.log('onConnect mqtt')
}

// called when the client loses its connection
function onConnectionLost (responseObject) {
  console.log(new Date().format('hh:mm:ss'), 'onConnectionLost:' + responseObject.errorMessage)
  client.connect(options)
}

// called when a message arrives
function onMessageArrived (message) {
  if (message.payloadString.length<200){
    console.log('onMessageArrived:', message.destinationName, message.payloadString)
  }
  else{
    ''.substring
    console.log('onMessageArrived:', 
    message.destinationName, message.payloadString.length,
    message.payloadString.substring(0, 10), '...', 
    message.payloadString.substring(message.payloadString.length-10, message.payloadString.length))
  }
  store.commit('MQTT', {message:message})
}

function GetID () {
  var d = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
  return 'xw-' + uuid
}

export { client }

<template>
  <div class="chart" id="chart" style="height: 560px; margin: 0">
    <div :id="id" style="height: 100%"></div>   
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'Chart',
  props: {
    id: String
  },
  data() {
    return {
      timerId:0,
      myChart : null,
      option : {
        animation : false,
        xAxis: {
          type: 'time',
          splitLine: {
            show: true
          },
          minInterval: this.$store.state.chartTime/5*1000,
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '100%'],
          splitLine: {
            show: true
          },
        },
        series: [
          {
            name: 'Fake Data',
            type: 'line',
            showSymbol: false,
          },
          {
            name: 'Fake Data2',
            type: 'line',
            showSymbol: true,
          },
        ]
      },
    }
  },
  methods: {
    Init () {
      var chartDom = document.getElementById(this.id);
      this.myChart = echarts.init(chartDom);
      this.myChart.setOption(this.option);
      window.onresize = this.myChart.resize;
    },
    Refresh() {
      // console.log('Chart Refresh', this.timerId, this.$store.state.time);
      var p=this.$store.state.chartStart
      var p0=(p-1+this.$store.state.chartTime)%this.$store.state.chartTime
      var v0={value:[]}
      var v1={value:[]}
      v0.value[0]=this.$store.state.slaves[this.$store.state.current.slave_i][this.$store.state.current.chart_i][p0].value[0]
      v0.value[1]=this.$store.state.current.param;
      v1.value[0]=this.$store.state.slaves[this.$store.state.current.slave_i][this.$store.state.current.chart_i][p].value[0]
      v1.value[1]=this.$store.state.current.param;
      var d=[]
      for (var i=0; i<this.$store.state.chartTime; i++){
        d[i]=this.$store.state.slaves[this.$store.state.current.slave_i][this.$store.state.current.chart_i][p]
        p++
        if (p>=this.$store.state.chartTime) p=0
      }
      this.myChart.setOption({series: [
          {data:d},
          {data:[v0,v1]}
        ]
      });
    }
  },
  created: function(){
    console.log('Chart created');
  },
  mounted: function(){
    this.Init()
    var p=this;
    this.timerId=setInterval(function () {
      p.Refresh()
    }, 1000);
    console.log('Chart mounted', this.timerId);
  },
  beforeDestroy: function(){
    clearInterval(this.timerId)
    console.log('Chart beforeDestroy', this.timerId)
  },
  destroyed: function(){
  }
}
</script>

<style scoped>
</style>

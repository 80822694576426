<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <div class="datetime">时间:{{$store.state.time}}</div>
    <div class="weather">
      {{$store.state.weather.temperature_curr}}
      {{$store.state.weather.humidity}}
      {{$store.state.weather.weather_curr}}
    </div>
    <div class="userinfo">欢迎{{$store.state.tel}}</div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
    }
  },
  methods: {
    
  },
  computed: {
  },
  mounted: function(){
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 0px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

<template>
  <div id="app">
    <HelloWorld msg="便携式电缆防破坏预警装置"/>
    <div id="nav">
      <router-link to="/">主页</router-link> |
      <router-link to="/history">历史记录</router-link> |
      <router-link to="/alarm">报警记录</router-link> |
      <router-link to="/param">参数设置</router-link> |
      <router-link to="/map">主机地图</router-link> |
      <a href="logout.php">退出</a>
    </div>
    <div class="weather">
      <div id="he-plugin-standard"></div>
    </div>
    <router-view/>
  </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'
export default {
  components: {
    HelloWorld,
  },
  props: {
  }, 
  methods:{
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#he-plugin-standard {
  width: 300px;
  float: right;
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Paho from 'paho-mqtt'
import { client } from '@/mqtt.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    alarm: false,
    alarmshow: false,
    tel: '110',
    runState: 0,
    runCount: 0,
    ip: '183.228.250.159',
    time: '',
    weather: {temperature_curr:'', humidity:'', weather_curr:''},
    current: {
      master_i : 0,
      slave_i : 0,
      chart_i : 12,
      master : '01',      
      slave  : '0101',
      chart  : 'vibration',
      param : '10',
      slave_c: 123,
    },
    param : {
      vibration : '10',
      temperature : '20',
      humidity : '30',
      content : '40',
      tel : '1359,123,124',
    },
    version: 'none',
    masters : [
      ['1', '01', '29.35469550', '107.66308130'],
      ['2', '02', '29.45469550', '107.76308130'],
    ],
    slaves : [      
      //0   1     2       3              4               5     6     7     8     9   10      11
      ['1', '01', '0101', '29.35469550', '107.66308130', 11.1, 12.1, 13.1, 14.1, 21, '0000', '0000'],
      ['2', '01', '0102', '29.45469550', '107.76308130', 21.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0103', '29.45469550', '107.76308130', 31.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0104', '29.45469550', '107.76308130', 41.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0105', '29.45469550', '107.76308130', 51.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0106', '29.45469550', '107.76308130', 61.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0107', '29.45469550', '107.76308130', 71.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0108', '29.45469550', '107.76308130', 81.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0109', '29.45469550', '107.76308130', 91.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0100', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['1', '01', '0111', '29.35469550', '107.66308130', 21.1, 12.1, 13.1, 14.1, 21, '0000', '0000'],
      ['2', '01', '0112', '29.45469550', '107.76308130', 31.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0113', '29.45469550', '107.76308130', 41.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0114', '29.45469550', '107.76308130', 51.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0115', '29.45469550', '107.76308130', 61.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0116', '29.45469550', '107.76308130', 71.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0117', '29.45469550', '107.76308130', 81.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0118', '29.45469550', '107.76308130', 91.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0119', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0110', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['1', '01', '0121', '29.35469550', '107.66308130', 11.1, 12.1, 13.1, 14.1, 21, '0000', '0000'],
      ['2', '01', '0122', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0123', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0124', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0125', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0126', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0127', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0128', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0129', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0120', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['1', '01', '0131', '29.35469550', '107.66308130', 11.1, 12.1, 13.1, 14.1, 21, '0000', '0000'],
      ['2', '01', '0132', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0133', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0134', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0135', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0136', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0137', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0138', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0139', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0130', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['1', '01', '0141', '29.35469550', '107.66308130', 11.1, 12.1, 13.1, 14.1, 21, '0000', '0000'],
      ['2', '01', '0142', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0143', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0144', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0145', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0146', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0147', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0148', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0149', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0140', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['1', '01', '0151', '29.35469550', '107.66308130', 11.1, 12.1, 13.1, 14.1, 21, '0000', '0000'],
      ['2', '01', '0152', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0153', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0154', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0155', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0156', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0157', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0158', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0159', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0150', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['1', '01', '0161', '29.35469550', '107.66308130', 11.1, 12.1, 13.1, 14.1, 21, '0000', '0000'],
      ['2', '01', '0162', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0163', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0164', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0165', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0166', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0167', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0168', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0169', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0160', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['1', '01', '0171', '29.35469550', '107.66308130', 11.1, 12.1, 13.1, 14.1, 21, '0000', '0000'],
      ['2', '01', '0172', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0173', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0174', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0175', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0176', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0177', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0178', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0179', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0170', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['1', '01', '0181', '29.35469550', '107.66308130', 11.1, 12.1, 13.1, 14.1, 21, '0000', '0000'],
      ['2', '01', '0182', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0183', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0184', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0185', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0186', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0187', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0188', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0189', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0180', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['1', '01', '0191', '29.35469550', '107.66308130', 11.1, 12.1, 13.1, 14.1, 21, '0000', '0000'],
      ['2', '01', '0192', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0193', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0194', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0195', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0196', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0197', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0198', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0199', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '01', '0190', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
      ['2', '02', '0290', '29.45469550', '107.76308130', 11.2, 12.2, 13.2, 14.2, 22, '0000', '0000'],
    ],
    historys : [
      ['1', '2020-1-1', '15:00:00', 11.1, 12.1, 13.1, 14.1, 21, '0000', '29.35469550', '107.66308130', ],
      ['1', '2020-1-1', '14:00:00', 18.1, 12.1, 13.1, 14.1, 21, '0000', '29.35469550', '107.66308130', ],
      ['1', '2020-1-1', '13:00:00', 31.1, 12.1, 13.1, 14.1, 21, '0000', '29.35469550', '107.66308130', ],
      ['1', '2020-1-1', '12:00:00', 22.1, 12.1, 13.1, 14.1, 21, '0000', '29.35469550', '107.66308130', ],
    ],
    alarms : [
      ['1', '2020-1-1', '15:00:00', '01', '0101', '1100', 11.1, 12.1, 13.1, 14.1, 21, '29.35469550', '107.66308130', ],
    ],
    chartTime : 5*60,
    chartStart: 0,
  },
  mutations: {
    Init (state) {
      console.log('init ', state.version)
      var now = new Date(new Date().valueOf()-state.chartTime*1000)
      var data=[]
      for (var i=0; i<state.chartTime; i++){
        var s=[now.getFullYear(), now.getMonth() + 1, now.getDate()].join('-')
        s=s+' '+[now.getHours(), now.getMinutes(), now.getSeconds()].join(':')
        data.push({value: [s, i%10]})
        now=new Date(now.valueOf()+1000)
      }
      for (i in state.slaves){
          state.slaves[i][12]=JSON.parse(JSON.stringify(data))
          state.slaves[i][13]=JSON.parse(JSON.stringify(data))
          state.slaves[i][14]=JSON.parse(JSON.stringify(data))
          state.slaves[i][15]=JSON.parse(JSON.stringify(data))
      }
      state.current.param=state.param.vibration
    },
    Refresh (state) {
      // console.log('Refresh', state);
      state.time=new Date().format('yyyy-MM-dd hh:mm:ss')
      state.alarmshow=state.alarm && (new Date().getSeconds()%2);
      state.runCount++
      if (state.runCount%10<=10){
        var now = new Date()
        var s=[now.getFullYear(), now.getMonth() + 1, now.getDate()].join('-')
        s=s+' '+[now.getHours(), now.getMinutes(), now.getSeconds()].join(':')
        // console.log(s);
        var c=0
        for (var i in state.slaves){
          state.slaves[i][12][state.chartStart]={value: [s, state.slaves[i][5]]}
          state.slaves[i][13][state.chartStart]={value: [s, state.slaves[i][6]]}
          state.slaves[i][14][state.chartStart]={value: [s, state.slaves[i][7]]}
          state.slaves[i][15][state.chartStart]={value: [s, state.slaves[i][8]]}
          if (state.slaves[i][1]==state.current.master) c++
        }
        state.chartStart++
        if (state.chartStart>=state.chartTime) state.chartStart=0
        state.current.slave_c=c
    }

      //run......
      if (state.runState==0){
        state.runState++
        axios.get('https://api.ipify.org/?format=json').then(function(rsp){
          state.ip=rsp.data.ip
          console.log('rsp', typeof(rsp), rsp.data, state.ip);
        }).catch(function(err){
          console.log('err', err);
        })
      } else if (state.runState==1) {                
        state.runState++;
        var url='http://api.k780.com/?app=weather.today&cityIp='+state.ip+'&appkey=10003&sign=b59bc3ef6191eb9f747dd4e83c99f2a4&format=json'
        axios.get(url).then(function(rsp){
          state.weather=rsp.data.result
          console.log('rsp', typeof(rsp), rsp.data)
          console.log('weather', typeof(state.weather), state.weather)
        }).catch(function(err){
          console.log('err', err);
        })
        console.log('state.runState', state.runState);
      }
    },
    UI(state, p){
      if ('master' in p) {
        state.current.master=p.master
        state.current.master_i=LocateMaster(state, state.current.master)
        if (state.current.master_i==-1){
          state.current.master_i=0
          state.current.master=state.masters[state.current.master_i][1]
        }
        state.current.slave_i=FirstSlave(state, state.current.master)
        if (state.current.slave_i==-1) state.current.slave_i=0
        state.current.slave=state.slaves[state.current.slave_i][2]
      }
      if ('slave' in p) {
        state.current.slave=p.slave
        state.current.slave_i=LocateSlave(state, state.current.master, state.current.slave)
        if (state.current.slave_i==-1) {
          state.current.slave_i=FirstSlave(state, state.current.master)
          if (state.current.slave_i==-1) state.current.slave_i=0
        }
        state.current.slave=state.slaves[state.current.slave_i][2]
      }
      if ('chart' in p) {
        state.current.chart=p.chart
        if ('vibration'==state.current.chart){
          state.current.chart_i=12
          state.current.param=state.param.vibration
        }
        if ('temperature'==state.current.chart){
          state.current.chart_i=13
          state.current.param=state.param.temperature
        }
        if ('humidity'==state.current.chart){
          state.current.chart_i=14
          state.current.param=state.param.humidity
        }
        if ('content'==state.current.chart){
          state.current.chart_i=15
          state.current.param=state.param.content
        }
      }
      console.log('UI', state, p, state.current)
    },
    Param(state, p){
      state.param.vibration=p.vibration;
      state.param.temperature=p.temperature;
      state.param.humidity=p.humidity;
      state.param.content=p.content;
      state.param.tel=p.tel;
      var message = new Paho.Message('s:'+JSON.stringify(state.param))
      message.destinationName = 'CXG2'
      client.send(message)
      console.log(message);
    },
    MQTT(state, p){
      // console.log(state, p, p.message.payloadString)
      var s=p.message.payloadString
      if (s.startsWith('d:')){
/*
d:[{"mid":"01", "sid":"0101", "lat":"29", "lng":"105", "vibration":"31", "temperature":"2", "humidity":"3", "content":"4", "battery":"25"}]
*/
        s=s.substring(2)
        var vs=JSON.parse(s)
        for (var i in vs) {
          var mi=LocateMaster(state, vs[i].mid)
          if (mi<0) continue
          var si=LocateSlave(state, vs[i].mid, vs[i].sid)
          if (si<0) continue
          state.masters[mi][2]=vs[i].lat
          state.masters[mi][3]=vs[i].lng
          
          state.slaves[si][3]=vs[i].lat
          state.slaves[si][4]=vs[i].lng
          
          Vue.set(state.slaves[si], 5, vs[i].vibration)
          Vue.set(state.slaves[si], 6, vs[i].temperature)
          Vue.set(state.slaves[si], 7, vs[i].humidity)
          Vue.set(state.slaves[si], 8, vs[i].content)
          Vue.set(state.slaves[si], 9, vs[i].battery)
        }
      } else if (s.startsWith('s:')){
/*
s:{"vibration":"44", "temperature":"33", "humidity":"22", "content":"11", "tel":"13594155387"}
*/
        s=s.substring(2)
        let v=JSON.parse(s)
        state.param.vibration=v.vibration
        state.param.temperature=v.temperature
        state.param.humidity=v.humidity
        state.param.content=v.content
        state.param.tel=v.tel
      } else if (s.startsWith('a:')){
/*
a:{"mid":"01", "sid":"0101", "alarm":"0001", "alarm2":"0001", "alarmMsg":"xxxx"}
*/
        s=s.substring(2)
        let v=JSON.parse(s)
        var master_i=LocateMaster(state, v.mid)
        if (master_i<0) return
        var slave_i=LocateSlave(state, v.mid, v.sid)
        if (slave_i<0) return
        Vue.set(state.slaves[slave_i], 10, v.alarm)
        Vue.set(state.slaves[slave_i], 11, v.alarmMsg)
        if (v.alarm!=v.alarm2 && v.alarm!='0000'){
          state.current.master=v.mid;
          state.current.slave=v.sid;
          state.current.master_i=master_i;
          state.current.slave_i=slave_i;
        }
        if (v.alarm!='0000') state.alarm=true
      }
    },
  },
  actions: {
  },
  modules: {
  },
})

function LocateMaster (state, mid){
  for (var i in state.masters){
    if (state.masters[i][1]==mid) return i    
  }
  return -1
}

function FirstSlave (state, mid){
  for (var i in state.slaves){
    if (state.slaves[i][1]==mid) return i    
  }
  return -1
}

function LocateSlave (state, mid, sid){
  for (var i in state.slaves){
    if (state.slaves[i][1]==mid && state.slaves[i][2]==sid) return i    
  }
  return -1
}

<template>
  <div class="home">
    <div class="container">
      <main class="main">
        <table>
          <tr>
            <td>主机编号</td>
            <td>
              <el-select v-model="$store.state.current.master" placeholder="请选择" @change="et_master()">
                <el-option
                  v-for="master in masters"
                  :key="master"
                  :label="master"
                  :value="master">
                </el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <td>从机数量</td>
            <td>{{$store.state.current.slave_c}}</td>
          </tr>
          <tr>
            <td>从机编号</td>
            <td>
              <input type="number" class="bh_input" v-model="$store.state.current.slave" placeholder="从机" @change="et_slave()"/>
            </td>
            <td>{{$store.state.current.slave}}</td>
          </tr>
          <tr>
            <td>振动</td>
            <td>{{$store.state.slaves[this.$store.state.current.slave_i][5]}} mm</td>
          </tr>
          <tr>
            <td>温度</td>
            <td>{{$store.state.slaves[this.$store.state.current.slave_i][6]}} °C</td>
          </tr>
          <tr>
            <td>湿度</td>
            <td>{{$store.state.slaves[this.$store.state.current.slave_i][7]}} RH%</td>
          </tr>
          <tr>
            <td>烟雾</td>
            <td>{{$store.state.slaves[this.$store.state.current.slave_i][8]}} </td>
          </tr>
          <tr>
            <td>报警状态</td>
            <td :style="alarmstyle"><el-button @click="bt_alarm">{{alarm}}</el-button></td>
            <td style="width: 40px; height: 40px;"><img src="alarm.png" width="40px" height="40px" v-show="alarmshow"/></td>
          </tr>
          <tr>
            <td>电池电量</td>
            <td>{{$store.state.slaves[this.$store.state.current.slave_i][9]}} V</td>
          </tr>
        </table>
      </main>
      <div class="sidebar">
        <el-tabs v-model="$store.state.current.chart" @tab-click="et_chart">
            <el-tab-pane label="振动" name="vibration">
              <p>振动 {{$store.state.slaves[this.$store.state.current.slave_i][5]}} mm</p>
            </el-tab-pane>
            <el-tab-pane label="温度" name="temperature">
              <p>温度 {{$store.state.slaves[this.$store.state.current.slave_i][6]}} °C</p>
            </el-tab-pane>
            <el-tab-pane label="湿度" name="humidity">
              <p>湿度 {{$store.state.slaves[this.$store.state.current.slave_i][7]}} RH%</p>
            </el-tab-pane>
            <el-tab-pane label="烟雾" name="content">
              <p>烟雾 {{$store.state.slaves[this.$store.state.current.slave_i][8]}} </p>
            </el-tab-pane>
        </el-tabs>
        <Chart id="chart1"/>
      </div>
    </div>

    <footer class="footer" role="contentinfo">
      <p>綦南供电分公司—重庆理工大学</p>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Chart from '@/components/Chart.vue'

export default {
  name: 'Home',
  components: {
    Chart
  },
  data() {
    return {
    }
  },
  computed: {
    masters: function() {
      var a=[]
      for (var i in this.$store.state.masters) {
        a.push(this.$store.state.masters[i][1])
      }
      return a
    },
    alarm: function() {
      var s=this.$store.state.slaves[this.$store.state.current.slave_i][10]
      return s.indexOf('0000')>=0?'正常':this.$store.state.slaves[this.$store.state.current.slave_i][11];
    },
    alarmstyle: function() {
      var s=this.$store.state.slaves[this.$store.state.current.slave_i][10]
      return {
        color : s.indexOf('0000')>=0? 'green' : 'red',
        border : '2px solid',        
      };
    },
    alarmshow: function(){
      return this.$store.state.alarmshow
    }
  },
  methods: {
    bt_alarm(){
      console.log('bt_alarm')
      this.$router.push('alarm')
    },
    et_master(){
      this.$store.commit('UI', {master:this.$store.state.current.master})
    },
    et_slave(){
      this.$store.commit('UI', {slave:this.$store.state.current.slave})
    },
    et_chart(tab, event) {
      console.log(tab, event);
      this.$store.commit('UI', {chart:this.$store.state.current.chart})
    },
  },
  mounted: function(){
    console.log('Home', 'mounted')
  },
  created: function(){
    console.log('Home', 'created')
  }
}
</script>

<style scoped>
/* table tr td {
  border: gray solid;
} */
</style>

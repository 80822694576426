import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import $ from 'jquery'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts';
require('echarts/extension/bmap/bmap')

import '@/mqtt.js'

// if ('???'===window.tel) window.location.replace('https://47hsir-demo.authing.cn/oidc/session/end')

Date.prototype.format = function(fmt) { 
  var o = { 
     "M+" : this.getMonth()+1,
     "d+" : this.getDate(),
     "h+" : this.getHours(),
     "m+" : this.getMinutes(),
     "s+" : this.getSeconds(),
     "q+" : Math.floor((this.getMonth()+3)/3), 
     "S"  : this.getMilliseconds()
 }; 
 if(/(y+)/.test(fmt)) {
         fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length)); 
 }
  for(var k in o) {
     if(new RegExp("("+ k +")").test(fmt)){
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
      }
  }
 return fmt; 
}

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(echarts)
Vue.use(VueAxios, axios)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

$(function () {
  console.log('jQuery ready')
})

console.log('Hello World main.js')
if (typeof window.version === 'undefined') {
  console.log('start as html')
} else {
  console.log('start as php', window.version)
  store.state.tel = window.tel
  store.state.version = window.version
  store.state.masters = window.masters
  store.state.slaves = window.slaves
  store.state.param.vibration = window.params[0][1]
  store.state.param.temperature = window.params[0][2]
  store.state.param.humidity = window.params[0][3]
  store.state.param.content = window.params[0][4]
  store.state.param.tel = window.params[0][5]
}
store.commit('Init')
setInterval(function () {
  store.commit('Refresh')
}, 1000)

// console.log(JSON.parse('[{"mid":"01", "sid":"0101", "lat":"29", "lng":"105", "vibration":"1", "temperature":"2", "humidity":"3", "content":"4", "battery":"25"}]'))
// console.log(new Date().format('yyyy-MM-dd hh:mm:ss'))
